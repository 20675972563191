import { Box, Divider, Flex, Space, Sx } from '@mantine/core'
import { ReactNode, RefObject, useCallback, useRef } from 'react'

import SideFooter from '~/components/layout/SideFooter'
import { useCurrentAuthState } from '~/context'
import { useScreenSize } from '~/hooks'
import { BANNER_HEIGHT, TOP_NAV_HEIGHT } from '~/utils/constants'

interface StickySideLayoutProps {
    scrollableContent: ReactNode
    stickyContent: ReactNode
    stickyContentWrapperRef: RefObject<HTMLDivElement>
    hideDivider?: boolean
    hideStickyMobile?: boolean
    sx?: Sx
    mobileFooterSx?: Sx
    reverse?: boolean
}
const StickySideLayout = ({
    scrollableContent,
    stickyContent,
    stickyContentWrapperRef,
    hideDivider,
    hideStickyMobile,
    reverse,
    mobileFooterSx = {},
    sx,
}: StickySideLayoutProps) => {
    const { isMobileScreen } = useScreenSize()
    const stickySideSectionRef = useRef<HTMLDivElement>(null)
    const footerRef = useRef<HTMLDivElement>(null)

    const authUser = useCurrentAuthState()
    const isSeller = !!authUser.userProfile?.stripeSellerId
    const stickyContentHeight = (stickyContentWrapperRef.current?.offsetHeight || 0) + 200 // padding

    const getStickySideSectionTopPosition = useCallback(() => {
        if (typeof window !== 'undefined') {
            const bannerHeight = isSeller ? BANNER_HEIGHT : 0
            const screenHeight = window.innerHeight

            const visibleScreenHeight = screenHeight - TOP_NAV_HEIGHT - bannerHeight

            if (stickyContentHeight > visibleScreenHeight) {
                return screenHeight - stickyContentHeight
            } else {
                return bannerHeight + TOP_NAV_HEIGHT + 40 // top padding
            }
        }
    }, [isSeller, stickyContentWrapperRef.current?.offsetHeight])

    const Footer = useCallback(
        () => (
            <Box ref={footerRef} className='md:max-w-[300px]'>
                <Divider
                    my={40}
                    color="rgba(0,0,0,0.1)"
                />
                <SideFooter />
                <Space h={40} />
            </Box>
        ),
        [footerRef]
    )

    return (
        <Flex
            sx={{ position: 'relative', ...sx }}
            direction={
                isMobileScreen ? `column${reverse ? '-reverse' : ''}` : `row${reverse ? '-reverse' : ''}`
            }
        >
            <Box sx={{ width: '100%' }}>{scrollableContent}</Box>

            {isMobileScreen ? (
                <Box>
                    {!hideStickyMobile ? stickyContent : null}
                    <Box sx={mobileFooterSx}>
                        <Footer />
                    </Box>
                </Box>
            ) : (
                <>
                    {!hideDivider && (
                        <Divider
                            orientation="vertical"
                            color="rgba(0,0,0,0.1)"
                            mx={56}
                        />
                    )}
                    <Box
                        ref={stickySideSectionRef}
                        sx={{
                            position: 'sticky',
                            top: getStickySideSectionTopPosition(),
                            height: stickyContentHeight,
                            maxHeight: stickyContentHeight,
                        }}
                        // className='md:max-w-[300px]'
                    >
                        {stickyContent}
                        <Footer />
                    </Box>
                </>
            )}
        </Flex>
    )
}

export default StickySideLayout
